import { TYPE_BROADCAST } from './types'
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Berkaitan dengan salah satu pemberlakuan protokol kesehatan, yakni pembatasan jumlah tamu, kami memohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i untuk hadir secara langsung. 

Meski demikian, di masa pandemi saat ini hal terpenting adalah sama-sama saling menjaga agar kita semua selalu sehat dan senantiasa dalam lindungan Tuhan.

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 
Mohon pengertiannya, terima kasih.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*`,

  [TYPE_BROADCAST.CUSTOM_INVITATION]: `Dear Mr/Ms/Mrs,
*{{GUEST_NAME}}*

Please click the Website Invitation link below for more information about our Engagement details:
{{LINK}}
_*) Due to WhatsApp security reasons, if the link is not clickable (the color is not blue), please try to click "Continue / Lanjutkan", reply "YES", or save this number to open the link._

Together with the grace and blessing of God, we cordially request the honour of your presence at the Engagement celebration of:

*Regina Wendy Lauvensia, BA (Hons) IHTM*
_The daughter of Mr. Sundaru and Mrs. Maryani_
& 
*Nicholas Trian Hartanto Halim, S.E.* 
_The son of Mr. Noerhadi Halim and Mrs. Herlina Ratnawati_

Together with the families, we will celebrate the wedding on *Saturday, 25 November 2023* at The Luxton Cirebon Hotel and Convention. 

We wish your understanding for all health protocols restrictions. 
See you on our Engagement day!

_With love,_
*Regina, Trian & family*
`,
}